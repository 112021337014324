<template>
  <div class="ChatList">
    <!-- 头部标题 -->
    <div class="header">
      <!-- <h2 class="header-title">{{$t("im.myDialogue")}}</h2> -->
      <van-search v-model="value" input-align="center" @blur="getQuery" :placeholder="$t('im.search')" />
    </div>
    <van-pull-refresh v-model="isLoading" class="myRefresh" @refresh="getQuery">
      <!-- 会话列表 -->
      <div class="chat-box">
        <div class="chatlist" v-if="chatlist.length != 0">
          <div
            v-for="(item, index) in chatlist"
            :key="index"
            class="chatlist-content"
            @click="gotoChatNews(item.chatId)"
          >
            <img :src="item.toUserFaceUrl" alt="" class="head-portrait">
            <div class="content">
              <div class="content-title">
                <h2>{{ item.toUserName }}</h2>
                <span>{{ formatData(item.lastMsgDt) }}</span>
              </div>
              <div class="content-text">
                <p>{{ item.lastMsgSummary }}</p>
                <div v-if="item.msgNewNum">{{ item.msgNewNum >99?"99+": item.msgNewNum }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="emtry" v-if="chatlist.length == 0">
          <img src="@/assets/img/im-emtry.png">
          <p class="txt">{{$t("im.imemtry")}}</p>
        </div>
      </div>
    </van-pull-refresh>
    <!-- 底部导航栏 -->
    <!-- <div class="Tabber"> -->
      <!-- <van-tabbar v-model="active">
        <van-tabbar-item badge="3">
          <span>自定义</span>
          <template #icon="props">
            <img :src="props.active ? icon.active : icon.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item icon="search">标签</van-tabbar-item>
        <van-tabbar-item icon="setting-o">标签</van-tabbar-item>
      </van-tabbar> -->
    <!-- </div> -->
    <!-- <div
      class="chat-list"
      v-for="(item, index) in chatlist"
      :key="index"
      @click="gotoChatNews(item.chatId)"
    >
      {{ item.toUserName }}
    </div> -->
  </div>
</template>
<script>
import moment from 'moment'

export default {
  data() {
    return {
      chatlist: [],
      value: '',
      active: 0,
      isLoading:false,
      icon: {
        active: 'https://img01.yzcdn.cn/vant/user-active.png',
        inactive: 'https://img01.yzcdn.cn/vant/user-inactive.png'
      }
    }
  },
  created() {
    document.title = this.$t("im.myIm");
    this.getQuery();
    setTimeout(()=>{
      this.heartBeep()
    })
  },
  methods: {
    // 查询会话
    async getQuery() {
      const toast = this.$Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t("message.loadingTxt")
      });
      const result = await this.$imapiAxio.post(
        '/im/cus-normal-chat-query',
        {
          pageNum: 1,
          pageSize: 10000,
          q:this.value
        })
      toast.clear();
      // if (errorCode !== 0) {
      //   this.$dialog.alert({
      //     message: this.$t('message.logintips')
      //   })
      // }
      this.isLoading = false;
      this.chatlist = [];
      result.data.data.forEach((item) => {
        this.chatlist.push({msgNewNum:item.unreadQty,...item})
      })
    },
    gotoChatNews(chatId) {
      this.$router.push({
        path: '/im/chat',
        query: {
          chatId: chatId,
          fromPage:120019,
          objId:chatId
        }
      })
    },
    async heartBeep() {
      var resData = await this.$imapiAxio.post('/im/heart-beep', {})
      if(resData.errorCode == 0&&resData.data.length>0){
        resData.data.forEach(item=>{
          this.chatlist.map(citem=>{
            if(item.chatId == citem.chatId){
              citem.msgNewNum ++;
              citem.lastMsgSummary = item.msgSummary
              citem.lastMsgSummary = item.msgSummary;
              if(item.msgBody.type == 'tip'&&item.chatName){
                  citem.toUserName = item.chatName;
              }
            }
          })
        })
      }
      setTimeout(()=>{
        this.heartBeep();
      },3000)
    },
    formatData(date) {
      moment.locale('zh-cn')
      const nowData = moment(Date.now()).format('YYYYMMDD HH:mm:ss')
      const chatDate = moment(date).format('YYYYMMDD HH:mm:ss')
      const nowInt = parseInt(nowData.slice(0, 8))
      const chatInt = parseInt(chatDate.slice(0, 8))
      // 上午
      // 下午
      const num1 = parseInt(nowData.slice(6, 8))
      const num2 = parseInt(chatDate.slice(6, 8))
      if (nowInt === chatInt) {
        return moment(Date.now()).format('ah:mm')
      }
      if (num2 === num1 - 1) {
        return this.$t("im.yesterday")
      }
      if (num2 >= num1 - 7 && num2 < num1 - 1) {
        return moment().format('dddd').replace(`/`+this.$t("im.week")+`/`, this.$t("im.weekly"))
      }
      return moment(date).format('YYYY-MM-DD')

      // 昨天
      // 这周
      // 这周之前
    }
  }
}
</script>
<style lang="scss" scoped>
.ChatList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 0.8rem;
    margin-bottom: 0.64rem;

    .header-title {
      margin: 0;
      padding: 0;
      margin-left: 0.68rem;
      margin-bottom: 0.8rem;
      font-size: 0.88rem;
      font-weight: 600;
      color: #242424;
    }
    .van-field__left-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      .van-icon {
        font-size: 0.64rem;
      }
    }
    .van-field__control {
      font-size: 0.6rem;
    }
    .van-search {
      width: 13.72rem;
      height: 1.4rem;
      background: #f5f6fa;
      border: 0.05rem solid #e3e3e3;
      border-radius: 0.36rem;
      margin-left: 0.68rem;
    }
  }
  .myRefresh{
    flex: 1;
  }
  .chat-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .chatlist {
      width: 100%;
      // min-height: 18.56rem;
      height: max-content;
      // overflow: hidden;
      padding-bottom: 0.1rem;

      .chatlist-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        margin: 0.8rem 0;
        padding: 0 0.64rem;

        .head-portrait {
          width: 1.8rem;
          height: 1.8rem;
          border-radius: 50%;
          vertical-align:middle;
          display: block;

        }

        .content {
          margin-left: 0.4rem;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          .content-title {
            height: 1rem;
            line-height: 1rem;
            display: flex;
            justify-content: space-between;

            h2 {
              margin: 0;
              padding: 0;
              font-size: 0.64rem;
              font-weight: 500;
              color: #191919;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

            }

            span {
              font-size: 0.44rem;
              font-weight: 400;
              color: #999999;
            }
          }

          .content-text {
            height: 0.8rem;
            line-height: 0.8rem;
            display: flex;
            justify-content: space-between;

            p {
              flex: 1;
              font-size: 0.52rem;
              font-weight: 400;
              color: #666666;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            div {
              flex: 0 0 auto;
              // min-width: ;
              width: 0.88rem;
              height: 0.88rem;
              padding: 0.1rem;
              // scale: 0.5;
              transform: scale(0.7);
              margin-top: -0.15rem;
              // padding: 0.2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #3387fd;
              border-radius: 50%;
              font-size: 0.52rem;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
    .emtry{
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      img{
        width: 5.4rem;
        height: 4rem;
        margin-bottom: 0.3rem;
      }
      p{
        font-size: 0.6rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #C0C4CC;
      }
    }
  }

  // .Tabber {
  //   width: 100%;
  //   height: 2.4rem;

  //   .van-tabbar {
  //     height: 2.4rem;
  //   }

  //   .van-tabbar-item {
  //     font-size: 0.48rem;
  //     font-weight: 400;
  //     color: #666666;
  //   }

  //   .van-tabbar-item--active {
  //     font-size: 0.52rem;
  //     font-weight: 600;
  //     color: #4a8dff;
  //   }
  // }
}
</style>
